<template>
	<h1>Arthur is a Digital Museum</h1>
	<p class="large">
		A modern-day Library of Alexandria, documenting visual culture from today, yesterday and tomorrow.
	</p>

	<br /><br />

	<h2>How it Works</h2>
	<p>
		Arthur is a digital exhibition platform with a focus on art and design. We provide the wall space, you
		provide the shows.
	</p>
	<p>
		All works uploaded to Arthur are indexed in our
		<a href="/search" @click.prevent="searchStore.activateOverlay">catalog</a>.
	</p>
	<!-- <p>Some examples:</p>
	<ul>
		<li v-for="(room, i) in exampleRooms" :key="i">
			<router-link :to="{ name: 'Room', params: { id: room.id } }">{{ room.name }}</router-link>
		</li>
	</ul> -->

	<br /><br />

	<h2>How to Join</h2>
	<p>
		Arthur is in private beta and accessible by invitation only. If you wish to get early access there's a
		few ways to skip the line.
	</p>

	<div class="buttons-wrap">
		<FormButton
			:level="1"
			value="Join wait list"
			@click="dialogStore.dialogByName('DialogAuth', { page: 'wait-list' })"
			class="bump-r"
		/>
		<FormButton :level="2" value="Skip the line" @click="dialogStore.dialogByName('DialogSkipLine')" />
	</div>
</template>

<script>
// Stores
// import { useApiStore } from '@/stores/ApiStore'
import { useSearchStore } from '@/stores/SearchStore'
import { useDialogStore } from '@/stores/DialogStore'

// Components
import FormButton from '@/components/FormButton'

// Internal
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'GeneralAbout',
	components: {
		FormButton,
	},
	setup() {
		injectMetaData({
			title: 'Arthur.io • About Arthur',
		})
		// const apiStore = useApiStore()
		// const clusterApi = apiStore.loadApi('cluster')
		// return { clusterApi }

		const searchStore = useSearchStore()
		const dialogStore = useDialogStore()
		return { searchStore, dialogStore }
	},
	data() {
		return {
			exampleRooms: [],
		}
	},
	// async mounted() {
	// 	const { status, data, statusText } = await this.clusterApi.getRandom(3)
	// 	if (status == 200) {
	// 		this.exampleRooms = data
	// 	} else {
	// 		handleApiError({ status, data, statusText, action: 'loading the example rooms' })
	// 	}
	// },
}
</script>

<style scoped lang="scss">
h1 {
	margin-bottom: 0.05rem;
}
</style>
